<template>
    <v-divider></v-divider>
</template>

<script>

export default {
    name: 'FormDivider',
    props: ['config'],
}
</script>
